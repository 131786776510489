export const BasicPageRoute = {
  TokenVerify: `token-verify`,
  ReportDownload: `report/download`,
  AccessDenied: `access-denied`,
}

const AccountPageRoutePrefix = `account/`;

export const LoginPageRoute = {
  Login: `${AccountPageRoutePrefix}login`,
  GoogleLogin: `google/login`,
  ResetPassword: `${AccountPageRoutePrefix}resetpassword`,
  Location: `${AccountPageRoutePrefix}location`,
  Onboarding: `${AccountPageRoutePrefix}onboarding`,
  MsTeamsManualLogin: `teams/manual/login`,
  MSLogin: `microsoft/login`,
  InvalidAttempts: `${AccountPageRoutePrefix}/security/invalid-attempts`,
  ReactivateAccountOtp: `${AccountPageRoutePrefix}/security/reactivate`
}

export const AccountPageRoute = {
  Layout: `${AccountPageRoutePrefix}`,
  RewardApproval: `${AccountPageRoutePrefix}reward-management/approvals`,
  Feed: `${AccountPageRoutePrefix}feed`,
  FeedExpand: `${AccountPageRoutePrefix}feed/expand`,
  FeedNotification: `${AccountPageRoutePrefix}feed`,
  // Insights: `${AccountPageRoutePrefix}insights`,
  Profile: `${AccountPageRoutePrefix}profile`,
  Setting: `${AccountPageRoutePrefix}settings`,
  InsightsGeneral: `${AccountPageRoutePrefix}insights/general`,
  InsightsAdvanced: `${AccountPageRoutePrefix}insights/advanced`,
  InsightsSurvey: `${AccountPageRoutePrefix}insights/survey`,
  UserSetting: {
    Layout: `${AccountPageRoutePrefix}settings`,
    Notification: `${AccountPageRoutePrefix}settings/notification`,
    Security: `${AccountPageRoutePrefix}settings/security`,
    ProgramSetting: `${AccountPageRoutePrefix}settings/program-settings/user`,
  }
}

const AdminPageRoutePrefix: string = `${AccountPageRoutePrefix}admin/`;

export const AdminPageRoute = {
  Layout: `${AdminPageRoutePrefix}`,
  ContactUs: `${AdminPageRoutePrefix}contact-us`,
  Home: `${AdminPageRoutePrefix}dashboard`,
  Rippling: `${AdminPageRoutePrefix}rippling/connect`,
  Profile: `${AdminPageRoutePrefix}profile`,
  AccountingReports: `${AdminPageRoutePrefix}reports/invoices`,
  ManageRoster: {
    Layout: `${AdminPageRoutePrefix}manage/roster`,
    Employees: `${AdminPageRoutePrefix}manage/roster/employees`,
    Admins: `${AdminPageRoutePrefix}manage/roster/admins`,
    Groups: `${AdminPageRoutePrefix}manage/roster/groups`
  },
  SlackConnect: `${AdminPageRoutePrefix}slack/connect`,
  Reward: `${AdminPageRoutePrefix}reward`,
  GroupsID: `${AdminPageRoutePrefix}groups/:id`,
  TeamsConnect: `${AdminPageRoutePrefix}microsoft-teams/connect`,
  ReminderSetting: `${AdminPageRoutePrefix}reminder`,
  Tags: {
    Layout: `${AdminPageRoutePrefix}admin-tags`,
    CoreValue: `${AdminPageRoutePrefix}admin-tags/core-value`,
    BusinessGoal: `${AdminPageRoutePrefix}admin-tags/business-goal`,
    RewardTags: `${AdminPageRoutePrefix}admin-tags/reward-tag`
  },
}

const AdminRewardSettingsPrefix: string = `${AdminPageRoute.Layout}reward-setting/`

export const AdminRewardSettingsPageRoute = {
  Layout: `${AdminRewardSettingsPrefix}`,
  RewardApprovals: `${AdminRewardSettingsPrefix}reward-approvals`,
  RewardControl: `${AdminRewardSettingsPrefix}reward-control`,
  RewardProgram: `${AdminRewardSettingsPrefix}reward-program`,
  RewardsBudget: `${AdminRewardSettingsPrefix}rewards-budget`,
}

const DeveloperPageRoutePrefix: string = `${AccountPageRoutePrefix}`;

export const DeveloperPageRoute = {
  LayoutV1: `${DeveloperPageRoutePrefix}/developers/v1`,
  LayoutV2: `${DeveloperPageRoutePrefix}/developers/v2`,

  ApiToken: `${DeveloperPageRoutePrefix}developers/v1/api-token`,
  Playground: `${DeveloperPageRoutePrefix}developers/v1/playground`,
  WebHooks: `${DeveloperPageRoutePrefix}developers/v1/webhooks`,
  ApiDocumentation: `${DeveloperPageRoutePrefix}developers/v1/api-documentation`,
  DocumentationV2: `${DeveloperPageRoutePrefix}developers/v2/documentation`,
  Settings: `${DeveloperPageRoutePrefix}developers/v2/settings`,
  WebHooksV2:`${DeveloperPageRoutePrefix}developers/v2/webhooks-v2`
}

const CorpPageRoutePrefix: string = `${AdminPageRoutePrefix}`;

export const CorpPageRoute = {
  UserManager: `${CorpPageRoutePrefix}client/user-manager`,
  VendorManager: `${CorpPageRoutePrefix}client/vendor-manager`,
  Product: `${CorpPageRoutePrefix}market-place/product`,
  Fareharbor: `${CorpPageRoutePrefix}market-place/fareharbor`,
  VendorCode: `${CorpPageRoutePrefix}market-place/vendor-code`,
  Viator: `${CorpPageRoutePrefix}market-place/viator`,
  Shopify: `${CorpPageRoutePrefix}market-place/shopify`,
  Amazon: `${CorpPageRoutePrefix}market-place/amazon`,
  AmazonLogHistory: `${CorpPageRoutePrefix}market-place/log-history`,
  Bookings: `${CorpPageRoutePrefix}market-place/bookings`,
  GiftCard: `${CorpPageRoutePrefix}manage-gift/gift-card`,
  SendGiftCard: `${CorpPageRoutePrefix}manage-gift/gift-card/send-gift`,
  AddEditGiftCard: `${CorpPageRoutePrefix}market-place/product/add-edit-giftcard`,
  AddEditExperience: `${CorpPageRoutePrefix}market-place/product/add-edit-experience`,
  AddEditFareharbor: `${CorpPageRoutePrefix}market-place/fareharbor/add-edit-fareharbor`,
  AddEditShopify: `${CorpPageRoutePrefix}market-place/shopify/add-edit-shopify`,
  AddEditVendorCode: `${CorpPageRoutePrefix}market-place/vendor-code/add-edit-vendorcode`,
  AddEditViator: `${CorpPageRoutePrefix}market-place/viator/add-edit-viator`,
  ProductMarkets: `${CorpPageRoutePrefix}market-place/markets`,
  ProductTags: `${CorpPageRoutePrefix}market-place/tags`,
  QuartzJob: `${CorpPageRoutePrefix}manage/quartz-job`,
  AmazonIntegration: `${CorpPageRoutePrefix}integration/amazon`,
  AmazonConnect: `${CorpPageRoutePrefix}amazon/redirect`,
  EmailHistory: `${CorpPageRoutePrefix}manage/emails/history`,
  RewardManagement: `${CorpPageRoutePrefix}manage/reward/management`,
  Invoices: `${CorpPageRoutePrefix}invoices`,
  ManualTransactions: `${CorpPageRoutePrefix}manual/transactions`,
  AdminTools: `${CorpPageRoutePrefix}admin/tools`,
  WishlistUpdates: `${CorpPageRoutePrefix}manage/wishlist-updates`,
  Accounts: `${CorpPageRoutePrefix}accounts`,
  Sites: `${CorpPageRoutePrefix}sites`,
  EngagementEmail: `${CorpPageRoutePrefix}manage/engagement-email`,
  SendWishlist: `${CorpPageRoutePrefix}send-wishlist`,
  ProductCollection: `${CorpPageRoutePrefix}product-collection`,
  BannerDisplay: `${CorpPageRoutePrefix}banner-display`,
  Brand: `${CorpPageRoutePrefix}brand`,
  Categories: `${CorpPageRoutePrefix}category`,
  SubCategory: `${CorpPageRoutePrefix}sub-category`,
  Vendor: `${CorpPageRoutePrefix}vendor`,
  SecurityCode: `${CorpPageRoutePrefix}manage/security-code`,
  Reports: `${CorpPageRoutePrefix}reports`,
  BestBuy: `${CorpPageRoutePrefix}market-place/best-buy`
}

const SettingPageRoutePrefix: string = `${AdminPageRoutePrefix}settings/`;

export const SettingPageRoute = {
  Layout: `${SettingPageRoutePrefix}`,
  CustomReactions: `${SettingPageRoutePrefix}custom-reactions`,
  ProgramSettings: `${SettingPageRoutePrefix}program-settings`,
  NotificationSettings: `${SettingPageRoutePrefix}notification-settings`,
  SurveySettings: `${SettingPageRoutePrefix}survey-settings`,
  ResetPassword: `${SettingPageRoutePrefix}reset-password`,
  Security: `${SettingPageRoutePrefix}security`,
  Onboarding: `${SettingPageRoutePrefix}onboarding`,
  Integrations: `${SettingPageRoutePrefix}integrations`,
  AccountBranding: `${SettingPageRoutePrefix}account-branding`
}

const RewardPageRoutePrefix: string = `${AdminPageRoutePrefix}reward/`;

export const RewardPageRoute = {
  Layout: `${RewardPageRoutePrefix}`,
  SentReward: `${RewardPageRoutePrefix}sent-rewards`,
  ThankYouNote: `${RewardPageRoutePrefix}thank-you-note`,
  UpcomingRewards: `${RewardPageRoutePrefix}upcoming-rewards`,
  UserReward: `${RewardPageRoutePrefix}my-rewards`,
  UserReceivedReward: `${RewardPageRoutePrefix}my-rewards/received-reward`,
  UserOrderedReward: `${RewardPageRoutePrefix}my-rewards/ordered-reward`,
  NewReward: `${RewardPageRoutePrefix}new-reward`,
  SingleNewReward: `${RewardPageRoutePrefix}new-reward/single-reward`,
  BulkNewReward: `${RewardPageRoutePrefix}new-reward/bulk-reward`,
  ApproveReward: `${RewardPageRoutePrefix}approve-rewards`,
  OrderReward: `${RewardPageRoutePrefix} ordered-rewards`,
  CustomReward: `${RewardPageRoutePrefix}custom-rewards`,
  CustomRedeemReward: `${RewardPageRoutePrefix}custom-rewards/redeemed`
}

const InsightV2RoutePrefix: string = `${AccountPageRoutePrefix}insight/v2/`

export const InsightPageRoute = {
  Layout: `${InsightV2RoutePrefix}`,
  KinectionView: `${InsightV2RoutePrefix}kinection-view`,
  ProgramDashboard: `${InsightV2RoutePrefix}program-dashboard`,
  WishlistExpenditure: `${InsightV2RoutePrefix}wishlist-expenditure`,
  RewardsSpendForecast: `${InsightV2RoutePrefix}rewards-spend-forecast`,
  RewardsLeaderBoard: `${InsightV2RoutePrefix}rewards-leader-board`,
  RewardAndRecognitionRecipientRatio: `${InsightV2RoutePrefix}reward-and-recognition-recipient-ratio`,
  ManagementReport: `${InsightV2RoutePrefix}management-report`,
  EngagementOverview: `${InsightV2RoutePrefix}engagement-overview`,
  RewardBalanceReport: `${InsightV2RoutePrefix}reward-balance-report`,
  TopTagsAnalysis: `${InsightV2RoutePrefix}top-tags-analysis`,
  RewardReciprocity: `${InsightV2RoutePrefix}reward-reciprocity`,
  LeastInteractionReport: `${InsightV2RoutePrefix}least-interaction-report`,
  SurveyAnalysis: `${InsightV2RoutePrefix}survey-analysis`,
  EngagementAcrossGroups: `${InsightV2RoutePrefix}engagement-across-groups`,
  TopOccasionSplit: `${InsightV2RoutePrefix}top-occasion-split`,
  EngagementSummary: `${InsightV2RoutePrefix}engagement-summary`,
  ProgramSpend: `${InsightV2RoutePrefix}program-spend`,
  ManagerDashboard: `${InsightV2RoutePrefix}manager-dashboard`
}

const MarketplaceRoutePrefix: string = `${AccountPageRoutePrefix}market-place/v4/`

export const MarketplaceRoute = {
  Layout: `${MarketplaceRoutePrefix}`,
  Home: `${MarketplaceRoutePrefix}home`,
  Favorite: `${MarketplaceRoutePrefix}favorite`,
  Listing: `${MarketplaceRoutePrefix}listing`,
  Travel: `${MarketplaceRoutePrefix}travel`,
  Details: `${MarketplaceRoutePrefix}details`,
  Booking: `${MarketplaceRoutePrefix}booking`,
  Search: `${MarketplaceRoutePrefix}search`,
  Collection: `${MarketplaceRoutePrefix}search-collection`,
  GenericListing: `${MarketplaceRoutePrefix}non-product-listing`,
}

const CorpBookingRoutePrefix: string = `${AdminPageRoute.Layout}market-place/`

export const CorpBookingRoute = {
  Layout: `${CorpBookingRoutePrefix}bookings/v2/`,
  General: `${CorpBookingRoutePrefix}bookings/v2/general`,
  GeneralManual: `${CorpBookingRoutePrefix}bookings/v2/general/manual`,
  GeneralExternal: `${CorpBookingRoutePrefix}bookings/v2/general/external`,
  Travel: `${CorpBookingRoutePrefix}bookings/v2/travel`,
  Hotel: `${CorpBookingRoutePrefix}bookings/v2/hotel`,
}
