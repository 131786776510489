import { createFeatureSelector } from "@ngrx/store";
import Account, { WalletConfig } from "../../modals/account";
import User from "../../modals/user";
import { NotificationBarData } from "../reducers/notification-bar.reducer";
import { SendRewardType } from "../actions/send-reward.action";
import { IInsightDashboardFilter } from "../reducers/insights-filters.reducer";

export const userSelector = createFeatureSelector<User>('user');
export const accountSelector = createFeatureSelector<Account>('account');
export const loadingConfig = createFeatureSelector<boolean>('configLoading');
export const walletConfig = createFeatureSelector<WalletConfig>('walletConfig');
export const notificationBarDataSelector = createFeatureSelector<NotificationBarData>('notificationBarData');
export const sendRewardSelector = createFeatureSelector<SendRewardType>('sendRewardType');
export const insightDashboardFilterSelector = createFeatureSelector<IInsightDashboardFilter>('insightsDashboardFilters');

