import { MarketplaceCategory } from "../../../modals/category";

export interface MarketplaceConfig {
    isDemoAccount?: boolean;
    walletBalance?: number;
    currencyCode?: string;
    banners: BannerConfig[]
    hasFavouriteProduct: boolean;
    categories: MarketplaceCategory[];
    isLoading: boolean;
    setUserWalletCurrency: boolean;
}


export interface BannerConfig {
    identifier: string;
    name: string;
    nameWithLocationTag?: string;
    subTitle: string;
    cTALabel: string;
    image: string;
    type: BannerType;
    pages: PageConfig[];
}

export interface SwimLaneBannerConfig extends BannerConfig {
    prodTopicVector: string;
    prodVectorMetadata: string;
    params: unknown;
    hasData?: boolean;
    productId?: number
}

export interface PageConfig {
    identifier: string;
    tag: string;
    sortOrder: number;
    typeSortOrder: number;

}


export enum BannerType {
    None = "None",
    HeadBanner = "HeadBanner",
    TorsoBanner = "TorsoBanner",
    Swimlane = "Swimlane",
    // Category ShowCase
    CircularItemMiddleTitleHighlightSwimlane = "CircularItemMiddleTitleHighlightSwimlane",
    // Sub Category
    NonTitledSwimlane = "NonTitledSwimlane",
    // Double Square swimlane
    DoubleLaneSquareSwimlane = "DoubleLaneSquareSwimlane",
    MiddleTitleHighlightSwimlane = "MiddleTitleHighlightSwimlane",
    HalfWidthTorsoBanner = "HalfWidthTorsoBanner"
}

export enum CollectionType {
    None = "None",
    Highlight = "Highlight",
    Trend = "Trend",
    Recommendation = "Recommendation"
}

export interface BannerAdjustmentDto {
    type: BannerType,
    data: BannerConfig | BannerConfig[];
    pages: PageConfig[]
    typeOrder?: number
}

export interface ICollectionViewEventData {
    CollectionName: string;
    CollectionType: BannerType;
    CollectionDisplay: string;
}

export const PAGE_MP_HOME: string = "marketplace-home";
export const PAGE_EXPERIENCE_COLLECTION: string = "marketplace-listing-category-experience";
export const PAGE_SWAP_COLLECTION: string = "marketplace-listing-category-swag";
export const PAGE_PHYSICAL_COLLECTION: string = "marketplace-listing-category-physical-product";
export const PAGE_ONLINE_COLLECTION: string = "marketplace-listing-category-online-exp";
export const PAGE_MP_FOOD_AND_DEV: string = 'marketplace-listing-category-foodbev';
export const PAGE_MP_PDP: string = "marketplace-pdp";
export const PAGE_MP_ORDER_CONF: string = "marketplace-order-confirmation";
export const PAGE_MP_LISTING_SEARCH: string = "marketplace-listing-search";
export const PAGE_MP_FOOD_AND_BEV: string = 'marketplace-listing-category-foodbev';
