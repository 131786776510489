import { logoutReducer } from "./logout.reducer";
import { overTimeReducer, OverTimeState, starReducer, StarState, tagChartReducer, TagsState } from './../../account/usage.insights/usage.insights.reducers';
import { isRewardAllowedReducer, rewardLoadingReducer, UserRewardState, userRewardReducer } from './../../account/user.reward/user.rewards.reducers';
import { StatsState, statsReducer } from '../../account/user.stats/user.stats.reducer';
import { RecentActivityState, recentActivityReducer } from '../../account/notification/notification.reducer';
import { newsFeedLastUpdatedAtReducer, userBudgetReducer, UserBudgetState } from '../../account/newsfeed/newsfeed.component/newfeed.reducers';
import { environment } from './../../../environments/environment.dev';
import { ThankyouState, thankyouNotesReducer } from "../../account/thankyou.notes/thankyou.notes.reducer";
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { TrendingTagsState, trendingTagsReducer } from '../../account/trending.tags/trending.tags.reducer';
import { PopularSearchedState, PopularSearchedStateReducer } from '../../account/start.post/init.post/filtering/popular.search.reducers';
import { TagSearchedState, tagSearchStateReducer } from '../../account/start.post/init.post/filtering/tag.search.reducers';
import { recognitionRenderNormalReducer, RecognitionRenderNormalState } from './../../account/recognition.render/normal/recognition.render.normal.reducers';
import { recognitionRenderFilterReducer, RecognitionRenderFilterState } from './../../account/recognition.render/filter/recognition.render.filter.reducers';
import { companyValueReducer, CompanyValueState } from "../../common/tag.store/company.value.reducer";
import { businessGoalReducer, BusinessGoalState } from "../../common/tag.store/business.goal.reducer";
import { rewardTagReducer, RewardTagState } from "../../common/tag.store/reward.tag.reducer";
import { surveyReducer, SurveyState } from "../../account/survey.card.feed/survey.reducer";
import { feedConfigReducer, ReactionDetailsInitialState } from "../reaction/reaction.details.store";
import { AccountReducer } from "./account.reducer";
import Account, { WalletConfig } from "../../modals/account";
import User from "../../modals/user";
import { UserReducer, configReducer } from "./user.reducer";
import { walletConfigReducer } from "./wallet.config.reducer";
import { exploreRewardReducer, ExploreRewardState } from "../../account/explore-rewards/explore.rewards.states";
import { leaderboardReducer, RnRewardLeaderboardYtdUserDtoState } from "../../account/leaderboard/leaderboard.states";
import { notificationBarDataReducer } from "./notification-bar.reducer";
import { InotificationBar } from "../../modals/notificationBar";
import { SendRewardReducer } from "./send-reward.reducer";
import { SendRewardType } from "../actions/send-reward.action";
import { IInsightDashboardFilter, insightDashboardFilterReducer } from "./insights-filters.reducer";

export interface AppState {
  stats: StatsState,
  recentActivity: RecentActivityState,
  userBudget: UserBudgetState,
  userRewards: UserRewardState
  rewardLoading: boolean,
  thanksYouNotes: ThankyouState,
  companyValue: CompanyValueState,
  businessGoal: BusinessGoalState,
  rewardTag: RewardTagState,
  overtime: OverTimeState
  stars: StarState,
  tagChart: TagsState,
  lastUpdatedAtNewsFeed: string;
  trendingTags: TrendingTagsState,
  popularSearch: PopularSearchedState,
  tagSearch: TagSearchedState,
  recognitionNormal: RecognitionRenderNormalState,
  recognitionFilter: RecognitionRenderFilterState,
  isRewardAllowed: boolean;
  surveyReducer: SurveyState,
  feedConfigReducer: ReactionDetailsInitialState,
  account: Account
  user: User,
  configLoading: boolean,
  walletConfig: WalletConfig,
  exploreReward: ExploreRewardState,
  leaderboard: RnRewardLeaderboardYtdUserDtoState,
  notificationBarData: InotificationBar,
  sendRewardType: SendRewardType
  insightsDashboardFilters: IInsightDashboardFilter
}

export const reducers: ActionReducerMap<AppState> = {
  user: UserReducer,
  account: AccountReducer,
  stats: statsReducer,
  recentActivity: recentActivityReducer,
  userBudget: userBudgetReducer,
  userRewards: userRewardReducer,
  rewardLoading: rewardLoadingReducer,
  thanksYouNotes: thankyouNotesReducer,
  companyValue: companyValueReducer,
  businessGoal: businessGoalReducer,
  rewardTag: rewardTagReducer,
  overtime: overTimeReducer,
  stars: starReducer,
  tagChart: tagChartReducer,
  lastUpdatedAtNewsFeed: newsFeedLastUpdatedAtReducer,
  trendingTags: trendingTagsReducer,
  popularSearch: PopularSearchedStateReducer,
  tagSearch: tagSearchStateReducer,
  recognitionNormal: recognitionRenderNormalReducer,
  recognitionFilter: recognitionRenderFilterReducer,
  isRewardAllowed: isRewardAllowedReducer,
  surveyReducer: surveyReducer,
  feedConfigReducer: feedConfigReducer,
  configLoading: configReducer,
  walletConfig: walletConfigReducer,
  exploreReward: exploreRewardReducer,
  leaderboard: leaderboardReducer,
  notificationBarData: notificationBarDataReducer,
  sendRewardType: SendRewardReducer,
  insightsDashboardFilters: insightDashboardFilterReducer
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logoutReducer] : [];
