import { Injectable } from '@angular/core';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import Account from '../../modals/account';
import { BookingDetail, Product } from '../../modals/booking';
import { ManagerUser } from '../../modals/manager.customer';
import { StoreService } from '../../store/store.service';
import { UserRole } from './../../modals/keys/variable.keys';

@Injectable({ providedIn: 'root' })

export class UtilityService {

  private approvalSubject = new Subject();
  private reloadCustomReward = new Subject();
  private booking = new ReplaySubject<BookingDetail>(1);
  private bookingFormSubmit = new Subject<BookingDetail>();
  private invokeFormSubmit = new ReplaySubject<boolean>(1);
  private bookingFulfillmentState = new Subject();
  private saveChangesNotifier = new Subject();
  private thankYouSub = new Subject<any>();
  private account: Account;

  constructor(private storeService: StoreService) {
    this.storeService.getAccount().subscribe(account => this.account = account);
  }

  //utility function to create copy of controls
  getObjectCopy(obj, itemGroup?: number, isOriginalKey?: boolean) {
    let newObj = new Object();
    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      newObj[keys[i]] = obj[keys[i]];
      if (itemGroup)
        newObj[keys[i]]['group'] = itemGroup;
      if (isOriginalKey)
        newObj[keys[i]]['originalKey'] = obj[i]['key']
    }
    return newObj;
  }

  prepareDataToGetEmailTemplate(data: BookingDetail) {
    let Obj = Object.create(data) as BookingDetail;
    let product = Object.create(data.product) as Product;
    let newObj: any = {};
    newObj.product = this.createProductObj(product);
    newObj.identifier = Obj.identifier;
    newObj.copyToVendor = Obj.copyToVendor;
    newObj.status = Obj.status;
    newObj.includeGoogleReviewRequest = Obj.includeGoogleReviewRequest;
    newObj.vendorBookingRequestConciergeNotes = Obj.vendorBookingRequestConciergeNotes;
    newObj.clientBookingConfirmationConciergeNotes = Obj.clientBookingConfirmationConciergeNotes;
    newObj.vendorBookingConfirmationConciergeNotes = Obj.vendorBookingConfirmationConciergeNotes;
    newObj.vendorEmailForRequest = Obj.vendorEmailForRequest;
    newObj.gratuity = '';
    newObj.clientEmail = Obj.clientEmail;
    newObj.confirmationNumber = Obj.confirmationNumber;
    newObj.waivers = Obj.waivers;
    newObj.comments = Obj.comments;
    newObj.emailTemplate = '';
    newObj.location = Obj.location;
    newObj.locationUrl = Obj.locationUrl;
    newObj.dateTimeOnConfirmation = Obj.dateTimeOnConfirmation;
    newObj.comments = Obj.product.bookingInstructionsHtml;
    return newObj;
  }

  createProductObj(pd: Product): any {
    let obj = {} as Product;
    obj.productNotesHtml = pd.productNotesHtml;
    obj.adminComment = pd.adminComment;
    obj.selectionDetailsHtml = pd.selectionDetailsHtml;
    obj.parentFormInfo = pd.parentFormInfo;
    obj.childFormInfo = pd.childFormInfo;
    obj.streetAddress = pd.streetAddress;
    obj.exclusionsHtml = pd.exclusionsHtml;
    obj.fullDescription = pd.fullDescription;
    obj.price = pd.price;
    obj.imageFilename = pd.imageFilename;
    obj.inclusionsHtml = pd.inclusionsHtml;
    obj.name = pd.name;
    obj.capacityValue = pd.capacityValue;
    obj.capacityType = pd.capacityType;
    obj.shortDescription = pd.shortDescription;
    obj.bookingInstructionsHtml = pd.bookingInstructionsHtml;
    obj.vendorDetails = pd.vendorDetails;
    obj.vendorPaymentDetails = pd.vendorPaymentDetails;
    obj.activityPaybackAmount = pd.activityPaybackAmount;
    return obj;
  }

  isFeedRefreshRequired(lastUpdatedDateTime: string): boolean {
    let lastDateTime = new Date(lastUpdatedDateTime);
    let currentDateTime = new Date();
    var difference = currentDateTime.getTime() - lastDateTime.getTime();
    let minutes = Math.round(difference / 60000)
    return minutes > 10 ? true : false;
  }

  public getDefaultApprover(): Observable<any> {
    return this.approvalSubject.asObservable();
  }

  public setDefaultApprover(approver) {
    return this.approvalSubject.next(approver)
  }

  removeUserFromList(list: ManagerUser[] = [], userGuid: string) {
    return list.filter(x => x?.userGuid != userGuid);
  }

  getRoleName(userSyncRole: string) {
    switch (userSyncRole) {
      case UserRole.EmployeeLogin:
        return "Employee";
      case UserRole.ProgramAdmin:
        return "Program Admin";
      case UserRole.ProgramManager:
        return "Program Manager";
      case UserRole.RewardSender:
        return "Reward Sender";
      default:
        return userSyncRole;
    }
  }

  setReloadCustomReward(value) {
    this.reloadCustomReward.next(value);
  }

  getReloadCustomReward() {
    return this.reloadCustomReward.asObservable();
  }

  setBookingData(data) {
    this.booking.next(data);
  }
  getBookingData(): Observable<BookingDetail> {
    return this.booking.asObservable();
  }
  setSubmittedFormData(value) {
    this.bookingFormSubmit.next(value)
  }
  getSubmittedFormData(): Observable<any> {
    return this.bookingFormSubmit.asObservable();
  }

  setInvokeBookingFormSubmit(value: boolean = false) {
    this.invokeFormSubmit.next(value);
  }
  getInvokeBookingFormSubmit(): Observable<boolean> {
    return this.invokeFormSubmit.asObservable();
  }

  getAccountLaunchDate() {
    let date = this.account?.accountLaunchDateUtc ?? null;
    return date ? new Date(date) : new Date(2012, 10, 1);
  }

  getBookingFulfilmentState() {
    return this.bookingFulfillmentState.asObservable();
  }
  setBookingFulfilmentState(statusData) {
    this.bookingFulfillmentState.next(statusData);
  }

  copyText(val: string) {
    try {
      let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      return true;
    } catch {
      return false;
    }
  }

  setSaveChangesNotifier(value) {
    this.saveChangesNotifier.next(value);
  }

  getSaveChangesNotifier() {
    return this.saveChangesNotifier.asObservable();
  }

  generatePassword(): string {
    var length = 10,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%&",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };
}
